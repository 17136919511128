/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import { animated, useSpring } from 'react-spring'
import { AiOutlineStar } from 'react-icons/ai'
import { AiFillStar } from 'react-icons/ai'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'

import { TestimonialType } from '../components/testimonials'

import { globalCss, variables, colors } from '../styles/global'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { LinkButton } from '../components/button'
import BorderButton from '../components/borderbutton'

const Downloads = ({ data }: any) => {
  return (
    <Layout hideMenu={false}>
      <div
        className="container"
        css={css`
          background-color: ${colors.black};
          color: ${colors.white};
          padding: 30px ${variables.HPaddingDesktop}px 40px
            ${variables.HPaddingDesktop}px;
          ${variables.mobile} {
            padding: 30px ${variables.HPaddingMobile}px 0px
              ${variables.HPaddingMobile}px;
          }
        `}
      >
        <h2
          css={css`
            margin: auto;
            margin-top: 100px;
            text-align: center;
          `}
        >
          Downloads
        </h2>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin: auto;
            justify-content: center;
            align-items: center;
          `}
        >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1Y8dzBZtm8cyiqvuih8yWOxnY83kCNBf-/view?usp=sharing"
            css={css`
              margin-top: 60px;
            `}
          >
            <BorderButton text="Download Hospitality Rider"></BorderButton>
          </a>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1pfqsE8YIMzhXT4qElACEz0O0Liou0m5q/view?usp=sharing"
            css={css`
              margin-top: 25px;
            `}
          >
            <BorderButton text="Download Technische Rider"></BorderButton>
          </a>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1Yg38FoW9VFsWYzeRBc9wV5XRiqX2q6hM/view?usp=sharing"
            css={css`
              margin-top: 25px;
            `}
          >
            <BorderButton text="Download Technische Rider (festivals)"></BorderButton>
          </a>
          <a
            target="_blank"
            href="https://drive.google.com/drive/folders/1mRhoKSpHLDIpxq236MS26DmXTP9gAw8J?usp=drive_link"
            css={css`
              margin-top: 25px;
            `}
          >
            <BorderButton text="Download Press Kit"></BorderButton>
          </a>
          <p
            css={css`
              max-width: 700px;
              text-align: center;
              margin-top: 50px;
            `}
          >
            Uitsluitend de afbeeldingen uit de presskit en onderstaande promo
            video gebruiken voor online/offline uitingen. Mis je iets of heb je
            een vraag, mail dan naar: info@robbenentertainment.com
          </p>

          <div
            className="youtube-video-container"
            css={css`
              max-width: 700px;
              margin-bottom: 200px;
            `}
          >
            <iframe
              id="promo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/OAJbegEruSA?si=ZXD2HcjT86K_hvP8&amp;controls=1&amp;autoplay=1&amp;modestbranding=1&amp;mute=1&amp;loop=1&amp;showinfo=0&amp;rel=0"
              title="First Class Flamingos PROMO"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export function Testimonial({
  text,
  namen,
  datum,
  show,
  maxLength,
  stars,
}: TestimonialType) {
  const [readMore, setReadMore] = useState(false)
  const [innerTxt, setInnerTxt] = useState(text)

  useEffect(() => {
    if (text.length > maxLength) {
      let shortTxt = splitAtFirstWhitespaceBeforeIndex(text, maxLength)
      shortTxt += '...'
      setReadMore(true)
      setInnerTxt(shortTxt)
    }
  }, [])

  return (
    <div
      className={show ? 'show' : 'hide'}
      css={css`
        transition: opacity 0.7s;
        background-color: ${colors.black};
        border: 2px solid ${colors.white};
        box-shadow: 0 0 5px ${colors.white};
        border-radius: 5px;
        padding: 5px 20px 5px 20px;
        margin: 40px 0;
        width: 80%;
        color: ${colors.lightBlue};
        position: relative;
        height: fit-content;
      `}
    >
      <p
        css={css`
          font-size: 2rem;
          margin-bottom: 0.3rem;
          color: ${colors.white};
        `}
      >
        <b>{namen}</b>
      </p>
      <p
        css={css`
          margin-top: 0;
          font-size: 1rem;
          color: ${colors.white};
        `}
      >
        {datum}
      </p>{' '}
      <hr
        css={css`
          border: 1px solid ${colors.black};
        `}
      />
      {[...Array(5)].map((star: any, index: number) => {
        if (index < stars) {
          return (
            <AiFillStar key={'star' + index} size="25px" color={colors.pink} />
          )
        } else {
          return (
            <AiOutlineStar
              key={'star' + index}
              size="25px"
              color={colors.pink}
            />
          )
        }
      })}
      <p
        css={css`
          font-size: 1rem;
        `}
      >
        <i>"{innerTxt}"</i>
      </p>
      {readMore && (
        <div
          css={css`
            margin-bottom: 20px;
          `}
        >
          <a href="/klantenvertellen">
            <u>Lees verder...</u>
          </a>
        </div>
      )}
    </div>
  )
}

function splitAtFirstWhitespaceBeforeIndex(string: string, index: number) {
  let splitIndex = 0
  for (let i = index; i > 0; i--) {
    if (string.charAt(i) == ' ') {
      splitIndex = i
      break
    }
  }

  return string.slice(0, splitIndex)
}

export default Downloads
