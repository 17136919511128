/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { globalCss, variables, colors } from '../styles/global'

export const BorderButton = ({ text }: any) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 2px solid ${colors.pink};
        padding: 8px 10px;
        border-radius: 8px;

        display: inline-block;
        background-color: ${colors.black};
        color: ${colors.white};
        transition: all 0.4s;
        &:hover {
          cursor: pointer;
          background-color: ${colors.white};
          color: ${colors.black};
          border: 2px solid ${colors.black};
        }
      `}
    >
      <p
        css={css`
          font-size: 1.5rem;
          margin: 0 10px 0 10px;
          font-family: 'Oswald';
          text-align: center;
          display: inline-block;
          ${variables.mobile} {
            font-size: 1.1rem;
          }
        `}
      >
        {text}
      </p>
    </div>
  )
}

export default BorderButton
